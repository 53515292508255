<template>
  <div class="center">
    <div class="spinner"></div>
    <p v-if="text" class="spinner-text">{{ text }}</p>
  </div>
</template>
<script>
export default {
  name: 'Spinner',
  props: {
    /**
     * Texto que aparece en el loader, si existe se muestra
     */
    text: { type: String, default: '' }
  }
};
</script>
<style lang="scss" src="./spinner.scss" scoped></style>
